<template>
    <div class="Quotation">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="150px">
            <el-form-item label="报价单名称" prop="name">
                <el-input @input="e => form.name = validForbid(e)" v-model="form.name" placeholder="请输入报价单名称" maxlength="100" :disabled="disabled" clearable/>
            </el-form-item>
            <el-form-item label="城市" prop="cityId">
                <el-select :ref="el=>refMap.set('cityId',el)" v-model="form.cityId" clearable placeholder="请选择城市" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'cityId')}">
                    <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item label="报价类型" prop="flag">
                <el-select :ref="el=>refMap.set('flag',el)" v-model="form.type" clearable placeholder="请选择报价类型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'flag')}">
                    <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
            </el-form-item>

            <el-form-item label="按跟客户结算百分比" v-if="moduleType=='driver'">
                <el-row>
                    <el-col :span="2"><el-switch v-model="percentFlag" :disabled="disabled"/></el-col>
                    <el-col :span="14" v-if="percentFlag">
                        <div style="display: flex;align-content: center">
                            <div><el-input-number v-model="form.percent" :min="0" precision=2 :disabled="disabled" controls-position="right" placeholder="请输入按客户结算费用的百分比"/></div>
                            <div>&nbsp;%</div>
                        </div>
                    </el-col>
                </el-row>
            </el-form-item>

        <el-tabs v-show="!percentFlag">
            <el-tab-pane label="车型">
                <el-checkbox-group v-model="form.cxSel">
                    <el-checkbox-button v-for="(item,index) in form.cxList" :key="index" :label="item.id" :disabled="disabled">
<!--                        {{item.name}}<br><br><span style="color:red;">({{item.capacity}}吨/{{item.size}}立方)</span>-->
                        {{item.name}}<br><br>({{item.capacity}}吨/{{item.size}}立方)
                    </el-checkbox-button>
                </el-checkbox-group>
            </el-tab-pane>
            <el-tab-pane label="区域">
                <div v-if="areaData.length>0">
<!--                    装货点<br>-->
                    <el-divider content-position="left">
                        装货点 &nbsp;&nbsp;&nbsp; <el-button type="info" circle @click="fromAreaIdSelHandler" :disabled="disabled">清除</el-button>
                    </el-divider>
                    <el-radio v-model="form.fromAreaId" v-for="(item,index) in areaData" :key="index" :label="item.value" :disabled="disabled">{{item.label}}</el-radio>

                    <el-divider content-position="left">卸货点</el-divider>
<!--                    <br>卸货点<br>-->
                    <el-checkbox-group v-model="form.toPlaceSel">
                        <el-checkbox-button v-for="(item,index) in areaData" :key="index" :label="item.value" :disabled="disabled">{{item.label}}</el-checkbox-button>
                    </el-checkbox-group>
                </div>
            </el-tab-pane>
            <el-tab-pane label="起步价">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="件数(件)">
                            <el-input-number v-model="form.num" :min="0" placeholder="请输入件数" :max="9999999999" precision="0" :disabled="disabled" style="width: 100%" clearable @change="(val,oldVal)=>{configRenewFist('reNewItemNum',val)}"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="重量(吨)">
                            <el-input-number v-model="form.weight" :min="0" placeholder="请输入重量" :max="9999999999" precision="0" :disabled="disabled" style="width: 100%" clearable @change="(val,oldVal)=>{configRenewFist('reNewItemWeight',val)}"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="体积(立方)">
                            <el-input-number v-model="form.size" :min="0" placeholder="请输入体积" :max="9999999999" precision="0" :disabled="disabled" style="width: 100%" clearable @change="(val,oldVal)=>{configRenewFist('reNewItemSize',val)}"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="配送点位(个)">
                            <el-input-number v-model="form.pointNum" :min="0" placeholder="请输入配送点位" :max="9999999999" precision="0" :disabled="disabled" style="width: 100%" clearable @change="(val,oldVal)=>{configRenewFist('reNewPointNum',val)}"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <EditTable :ref="el=>refMap.set('startDistance',el)" v-bind="cardDetailParam('startDistance')">
                    <template v-slot:tbCols>
                        <el-table-column label="开始里程（不包含）" prop="F_BEGAN"/>
                        <el-table-column label="结束里程 KM（包含）" prop="F_END">
                            <template #default="scope">
                                <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_END}}</span>
                                <el-input-number v-else v-model="scope.row.F_END"  placeholder="请输入结束里程,不填则为∞" :max="99999" precision="0" style="width: 100%" clearable/>
                            </template>
                        </el-table-column>
                    </template>
                </EditTable>
            </el-tab-pane>
            <el-tab-pane label="续费">
                <el-tabs type="card">
                    <el-tab-pane label="里程">
                        <EditTable :ref="el=>refMap.set('reNewDistance',el)" v-bind="cardDetailParam('reNewDistance')">
                            <template v-slot:tbCols>
                                <el-table-column label="开始里程（不包含）" prop="F_BEGAN"/>
                                <el-table-column label="结束里程 KM（包含）" prop="F_END"  >
                                    <template #default="scope">
                                        <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_END}}</span>
                                        <el-input-number v-else v-model="scope.row.F_END"  placeholder="请输入结束里程,不填则为∞" precision="0" :max="99999"  style="width: 100%" clearable/>
                                    </template>
                                </el-table-column>
                            </template>
                        </EditTable>
                    </el-tab-pane>
                    <el-tab-pane label="件数">
                        <EditTable :ref="el=>refMap.set('reNewItemNum',el)" v-bind="cardDetailParam('reNewItemNum')">
                            <template v-slot:tbCols>
                                <el-table-column label="开始件数（不包含）" prop="F_BEGAN"/>
                                <el-table-column label="结束件数 件（包含）" prop="F_END"  >
                                    <template #default="scope">
                                        <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_END}}</span>
                                        <el-input-number v-else v-model="scope.row.F_END"  placeholder="请输入结束件数,不填则为∞" precision="0" :max="99999"  style="width: 100%" clearable/>
                                    </template>
                                </el-table-column>
                            </template>
                        </EditTable>
                    </el-tab-pane>
                    <el-tab-pane label="重量">
                        <EditTable :ref="el=>refMap.set('reNewItemWeight',el)" v-bind="cardDetailParam('reNewItemWeight')">
                            <template v-slot:tbCols>
                                <el-table-column label="开始重量（不包含）" prop="F_BEGAN"/>
                                <el-table-column label="结束重量 吨（包含）" prop="F_END"  >
                                    <template #default="scope">
                                        <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_END}}</span>
                                        <el-input-number v-else v-model="scope.row.F_END"  placeholder="请输入结束重量,不填则为∞" precision="0" :max="99999"  style="width: 100%" clearable/>
                                    </template>
                                </el-table-column>
                            </template>
                        </EditTable>
                    </el-tab-pane>
                    <el-tab-pane label="体积">
                        <EditTable :ref="el=>refMap.set('reNewItemSize',el)" v-bind="cardDetailParam('reNewItemSize')">
                            <template v-slot:tbCols>
                                <el-table-column label="开始体积（不包含）" prop="F_BEGAN"/>
                                <el-table-column label="结束体积 立方（包含）" prop="F_END"  >
                                    <template #default="scope">
                                        <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_END}}</span>
                                        <el-input-number v-else v-model="scope.row.F_END"  placeholder="请输入结束体积,不填则为∞" precision="0" :max="99999"  style="width: 100%" clearable/>
                                    </template>
                                </el-table-column>
                            </template>
                        </EditTable>
                    </el-tab-pane>
                    <el-tab-pane label="配送点位">
                        <EditTable :ref="el=>refMap.set('reNewPointNum',el)" v-bind="cardDetailParam('reNewPointNum')">
                            <template v-slot:tbCols>
                                <el-table-column label="开始点位数（不包含）" prop="F_BEGAN"/>
                                <el-table-column label="结束点位数 个（包含）" prop="F_END"  >
                                    <template #default="scope">
                                        <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_END}}</span>
                                        <el-input-number v-else v-model="scope.row.F_END"  placeholder="请输入结束点位数,不填则为∞" precision="0" :max="99999"  style="width: 100%" clearable/>
                                    </template>
                                </el-table-column>
                            </template>
                        </EditTable>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
        </el-tabs>
        </el-form>
    </div>
</template>


<script>
    import QuotationCardHelper from "./QuotationCardHelper.js";
    export default QuotationCardHelper;
</script>

<style scoped>
    .Quotation{
        width: 100%;
    }
    /*否则禁用el-checkbox-button之后，已经选中的看不见*/
    .Quotation ::v-deep(.el-checkbox-button.is-disabled.is-checked .el-checkbox-button__inner) {
        color: #FFFFFF;
        background-color: #1890ff;
        border-color: #1890ff;
        -webkit-box-shadow: -1px 0 0 0 #74bcff;
        box-shadow: -1px 0 0 0 #74bcff;
    }
</style>
